<!--
 * @version: 1.0
 * @Date: 2023-05-17 13:45:50
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="number-input-wrapper design-form-style">
    <div class="flex-layout">
      <div class="label">
        <span class="required-icon" v-if="required">*</span>
        <span>{{ title }}</span>
      </div>
      <div class="placeholder">
        {{ placeholder || '请输入' }}
        <span class="unit">{{ currencyType ==='人民币'?'元':currencyType }}</span>
      </div>
    </div>
    <div :class="{'tips': true, 'tips-label': required }" v-if="tipsText">{{ tipsText }}</div>
  </div>
</template>

<script setup>
defineProps(['title', 'placeholder', 'tipsText', 'required', 'currencyType'])
</script>

<style lang='less' scoped>
.flex-layout {
  justify-content: space-between;
}
.unit {
  color: #333;
  margin-left: 16px;
}
.required-icon{
  color:#c3161c !important;
}
</style>